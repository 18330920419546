import React from "react"
import FacebookIcon from "../svg/facebook-icon.svg"
import LinkedInIcon from "../svg/linkedin-icon.svg"
import GoogleIcon from "../svg/google-icon.svg"
import TwitterIcon from "../svg/twitter-icon.svg"
import { LinkPart } from "../parts/link"
import { StyledShareButtons } from "./share-buttons.styles"
import { useLocation } from "@reach/router"

const ShareButtons: React.FC<any> = props => {
  const [pageLink, setPageLink] = React.useState("")
  const location = useLocation()

  React.useEffect(() => {
    setPageLink(location.href)

    return () => {}
  }, [])

  return (
    <StyledShareButtons>
      <ul className="share-buttons__list">
        <li className="share-button">
          <LinkPart
            className="share-button__link share-button__link--facebook"
            to={`https://www.facebook.com/dialog/share?app_id=2687517384859162&display=popup&href=${pageLink}&redirect_uri=${pageLink}`}
          >
            <FacebookIcon />
          </LinkPart>
        </li>
        <li className="share-button">
          <LinkPart
            className="share-button__link share-button__link--linkedin"
            to={`https://www.linkedin.com/sharing/share-offsite/?url=${pageLink}`}
          >
            <LinkedInIcon />
          </LinkPart>
        </li>
        {/* <li className="share-button">
                    <LinkPart className="share-button__link share-button__link--google" to={''}>
                        <GoogleIcon />
                    </LinkPart>
                </li> */}
        <li className="share-button">
          <LinkPart
            className="share-button__link share-button__link--twitter"
            to={`https://twitter.com/intent/tweet?url=${pageLink}`}
          >
            <TwitterIcon />
          </LinkPart>
        </li>
      </ul>
    </StyledShareButtons>
  )
}

export default ShareButtons
