import styled from 'styled-components';
import { spacing, blockSpacing } from '../global/spacing.styles';
import { themedColor, themedBackground } from '../global/theme.styles';
import { fancyH1Styles, BlogAttributionTextStyles } from '../global/typography.styles';
import { StyledImage } from '../parts/image.styles';

export const StyledBlogPostPage = styled.div`
    ${themedBackground};
    ${themedColor};
    padding-top: ${spacing.pageTopPadding}px;

    & .blog-post {

        &__featured-image {
            & ${StyledImage} {
                width: 100%;
                height: calc(50vh - 90px);

                .gatsby-image-wrapper {
                    height: 100%;
                }

                img {
                    width: 100%;
                    height: calc(50vh - 90px);
                    max-width: none;
                    object-fit: cover;
                }
            }
        }

        &__title {
            ${fancyH1Styles}
            ${blockSpacing}
            overflow-wrap: anywhere;
            word-break: break-word;
        }

        &__attribution,
        &__date {
            ${BlogAttributionTextStyles}
            ${blockSpacing}
            overflow-wrap: anywhere;
            word-break: break-word;
        }
    }
`;
