import styled from 'styled-components';
import { colors } from '../global/theme.styles';
import { h3Styles } from '../global/typography.styles';
import { baseEasingFunction } from '../global/animation.styles';
import { facebookIconColorStyles, linkedinIconColorStyles, googleIconColorStyles, twitterIconColorStyles } from '../global/icon.styles';
import { blockSpacing } from '../global/spacing.styles';

export const StyledShareButtons = styled.div`
    ${blockSpacing}

    & .share-buttons {

        &__title {
            ${h3Styles}
            display: inline-block;
        }
    }

    & .share-button {
        display: inline-block;
        margin-right: 10px;
    }

    .facebook-icon,
    .linkedin-icon,
    .google-icon,
    .twitter-icon {
        width: 25px;
        height: 25px;
    }

    .facebook-icon {
        ${facebookIconColorStyles}
    }

    .linkedin-icon {
        ${linkedinIconColorStyles}
    }

    .google-icon {
        ${googleIconColorStyles}
    }

    .twitter-icon {
        ${twitterIconColorStyles}
    }
`;
