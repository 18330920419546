import React from "react";
import { graphql } from "gatsby";
import SEO from "../components/seo";
import { Blocks } from "../gutenberg/blocks";
import { StyledBlogPostPage } from "./blog-post.styles";
import { ImagePart } from "../parts/image";
import ShareButtons from "../parts/share-buttons";
import { formatDate } from "../helpers/dates";
import { getFeaturedImage } from "../helpers/image";
import { StyledParagraph } from "../gutenberg/blocks/paragraph.styles";
import { PostQueryQuery } from "../../gatsby-graphql";
import { siteTheme } from "../global/theme.styles";

export interface IBlogPostProps {
  data: PostQueryQuery;
}

const BlogPostTemplate: React.FC<IBlogPostProps> = props => {
  const post = props.data.wpPost;
  const theme = siteTheme;

  return (
    <StyledBlogPostPage className="template template--blog-post">
      <SEO title={post?.title} 
        description={post?.excerpt}
        image={post?.featuredImage?.node?.src?.childImageSharp && getFeaturedImage(post.featuredImage.node.src?.childImageSharp.gatsbyImageData)}
      />
      <div className="blog-post__featured-image">
        {post?.featuredImage?.node?.src?.childImageSharp && 
          <ImagePart src={post.featuredImage.node.src.childImageSharp.gatsbyImageData} priority="eager" />
        }
      </div>
      <h1 className="blog-post__title">{post?.title}</h1>
      <StyledParagraph className="blog-post__date" paragraphFontSize={"small"} >
        {post?.date && formatDate(post.date)}
        {/* {post?.author?.node?.name &&
          <><br />Written by {post.author.node.name}</>
        } */}
      </StyledParagraph>
      <ShareButtons />
      {post?.blocks && <Blocks blocks={post.blocks} theme={theme} />}
    </StyledBlogPostPage>
  )
}

export default BlogPostTemplate

export const query = graphql`
  query PostQuery($id: String) {
      wpPost(id: { eq: $id } ) {
        title
        date
        author {
          node {
            name
          }
        }
        excerpt
        featuredImage {
          node {
            ...ImageFragment
          }
        }
        blocks {
          ...BlocksFragment
        }
      }
  }
`